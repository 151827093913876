export const chainsList = [
  {
		"name": "Ethereum Mainnet",
		"chain": "ETH",
		"icon": "ethereum",
		"rpc": [
			"https://mainnet.infura.io/v3/${INFURA_API_KEY}",
			"wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}",
			"https://api.mycryptoapi.com/eth",
			"https://cloudflare-eth.com"
		],
		"faucets": [],
		"nativeCurrency": {
			"name": "Ether",
			"symbol": "ETH",
			"decimals": 18
		},
		"infoURL": "https://ethereum.org",
		"shortName": "eth",
		"chainId": 1,
		"networkId": 1,
		"slip44": 60,
		"ens": {
			"registry": "0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e"
		},
		"explorers": [
			{
				"name": "etherscan",
				"url": "https://etherscan.io",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Ropsten",
		"title": "Ethereum Testnet Ropsten",
		"chain": "ETH",
		"network": "testnet",
		"rpc": [
			"https://ropsten.infura.io/v3/${INFURA_API_KEY}",
			"wss://ropsten.infura.io/ws/v3/${INFURA_API_KEY}"
		],
		"faucets": [
			"http://fauceth.komputing.org?chain=3&address=${ADDRESS}",
			"https://faucet.ropsten.be?${ADDRESS}"
		],
		"nativeCurrency": {
			"name": "Ropsten Ether",
			"symbol": "ROP",
			"decimals": 18
		},
		"infoURL": "https://github.com/ethereum/ropsten",
		"shortName": "rop",
		"chainId": 3,
		"networkId": 3,
		"ens": {
			"registry": "0x112234455c3a32fd11230c42e7bccd4a84e02010"
		},
		"explorers": [
			{
				"name": "etherscan",
				"url": "https://ropsten.etherscan.io",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Rinkeby",
		"title": "Ethereum Testnet Rinkeby",
		"chain": "ETH",
		"network": "testnet",
		"rpc": [
			"https://rinkeby.infura.io/v3/${INFURA_API_KEY}",
			"wss://rinkeby.infura.io/ws/v3/${INFURA_API_KEY}"
		],
		"faucets": [
			"http://fauceth.komputing.org?chain=4&address=${ADDRESS}",
			"https://faucet.rinkeby.io"
		],
		"nativeCurrency": {
			"name": "Rinkeby Ether",
			"symbol": "RIN",
			"decimals": 18
		},
		"infoURL": "https://www.rinkeby.io",
		"shortName": "rin",
		"chainId": 4,
		"networkId": 4,
		"ens": {
			"registry": "0xe7410170f87102df0055eb195163a03b7f2bff4a"
		},
		"explorers": [
			{
				"name": "etherscan-rinkeby",
				"url": "https://rinkeby.etherscan.io",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Kovan",
		"title": "Ethereum Testnet Kovan",
		"chain": "ETH",
		"network": "testnet",
		"rpc": [
			"https://kovan.poa.network",
			"http://kovan.poa.network:8545",
			"https://kovan.infura.io/v3/${INFURA_API_KEY}",
			"wss://kovan.infura.io/ws/v3/${INFURA_API_KEY}",
			"ws://kovan.poa.network:8546"
		],
		"faucets": [
			"http://fauceth.komputing.org?chain=42&address=${ADDRESS}",
			"https://faucet.kovan.network",
			"https://gitter.im/kovan-testnet/faucet"
		],
		"nativeCurrency": {
			"name": "Kovan Ether",
			"symbol": "KOV",
			"decimals": 18
		},
		"explorers": [
			{
				"name": "etherscan",
				"url": "https://kovan.etherscan.io",
				"standard": "EIP3091"
			}
		],
		"infoURL": "https://kovan-testnet.github.io/website",
		"shortName": "kov",
		"chainId": 42,
		"networkId": 42
	},
  {
		"name": "Görli",
		"title": "Ethereum Testnet Görli",
		"chain": "ETH",
		"network": "testnet",
		"rpc": [
			"https://goerli.infura.io/v3/${INFURA_API_KEY}",
			"wss://goerli.infura.io/v3/${INFURA_API_KEY}",
			"https://rpc.goerli.mudit.blog/"
		],
		"faucets": [
			"http://fauceth.komputing.org?chain=5&address=${ADDRESS}",
			"https://goerli-faucet.slock.it?address=${ADDRESS}",
			"https://faucet.goerli.mudit.blog"
		],
		"nativeCurrency": {
			"name": "Görli Ether",
			"symbol": "GOR",
			"decimals": 18
		},
		"infoURL": "https://goerli.net/#about",
		"shortName": "gor",
		"chainId": 5,
		"networkId": 5,
		"ens": {
			"registry": "0x112234455c3a32fd11230c42e7bccd4a84e02010"
		},
		"explorers": [
			{
				"name": "etherscan-goerli",
				"url": "https://goerli.etherscan.io",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Binance Smart Chain Mainnet",
		"chain": "BSC",
		"rpc": [
			"https://bsc-dataseed1.binance.org",
			"https://bsc-dataseed2.binance.org",
			"https://bsc-dataseed3.binance.org",
			"https://bsc-dataseed4.binance.org",
			"https://bsc-dataseed1.defibit.io",
			"https://bsc-dataseed2.defibit.io",
			"https://bsc-dataseed3.defibit.io",
			"https://bsc-dataseed4.defibit.io",
			"https://bsc-dataseed1.ninicoin.io",
			"https://bsc-dataseed2.ninicoin.io",
			"https://bsc-dataseed3.ninicoin.io",
			"https://bsc-dataseed4.ninicoin.io",
			"wss://bsc-ws-node.nariox.org"
		],
		"faucets": [
			"https://free-online-app.com/faucet-for-eth-evm-chains/"
		],
		"nativeCurrency": {
			"name": "Binance Chain Native Token",
			"symbol": "BNB",
			"decimals": 18
		},
		"infoURL": "https://www.binance.org",
		"shortName": "bnb",
		"chainId": 56,
		"networkId": 56,
		"slip44": 714,
		"explorers": [
			{
				"name": "bscscan",
				"url": "https://bscscan.com",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Binance Smart Chain Testnet",
		"chain": "BSC",
		"rpc": [
			"https://data-seed-prebsc-1-s1.binance.org:8545",
			"https://data-seed-prebsc-2-s1.binance.org:8545",
			"https://data-seed-prebsc-1-s2.binance.org:8545",
			"https://data-seed-prebsc-2-s2.binance.org:8545",
			"https://data-seed-prebsc-1-s3.binance.org:8545",
			"https://data-seed-prebsc-2-s3.binance.org:8545"
		],
		"faucets": [
			"https://testnet.binance.org/faucet-smart"
		],
		"nativeCurrency": {
			"name": "Binance Chain Native Token",
			"symbol": "tBNB",
			"decimals": 18
		},
		"infoURL": "https://testnet.binance.org/",
		"shortName": "bnbt",
		"chainId": 97,
		"networkId": 97,
		"explorers": [
			{
				"name": "bscscan-testnet",
				"url": "https://testnet.bscscan.com",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Huobi ECO Chain Mainnet",
		"chain": "Heco",
		"rpc": [
			"https://http-mainnet.hecochain.com",
			"wss://ws-mainnet.hecochain.com"
		],
		"faucets": [
			"https://free-online-app.com/faucet-for-eth-evm-chains/"
		],
		"nativeCurrency": {
			"name": "Huobi ECO Chain Native Token",
			"symbol": "HT",
			"decimals": 18
		},
		"infoURL": "https://www.hecochain.com",
		"shortName": "heco",
		"chainId": 128,
		"networkId": 128,
		"slip44": 1010,
		"explorers": [
			{
				"name": "hecoinfo",
				"url": "https://hecoinfo.com",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Hoo Smart Chain",
		"chain": "HSC",
		"rpc": [
			"https://http-mainnet.hoosmartchain.com",
			"https://http-mainnet2.hoosmartchain.com",
			"wss://ws-mainnet.hoosmartchain.com",
			"wss://ws-mainnet2.hoosmartchain.com"
		],
		"faucets": [],
		"nativeCurrency": {
			"name": "Hoo Smart Chain Native Token",
			"symbol": "HOO",
			"decimals": 18
		},
		"infoURL": "https://www.hoosmartchain.com",
		"shortName": "hsc",
		"chainId": 70,
		"networkId": 70,
		"slip44": 1170,
		"explorers": [
			{
				"name": "hooscan",
				"url": "https://www.hooscan.com",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Fantom Opera",
		"chain": "FTM",
		"rpc": [
			"https://rpc.ftm.tools"
		],
		"faucets": [
			"https://free-online-app.com/faucet-for-eth-evm-chains/"
		],
		"nativeCurrency": {
			"name": "Fantom",
			"symbol": "FTM",
			"decimals": 18
		},
		"infoURL": "https://fantom.foundation",
		"shortName": "ftm",
		"chainId": 250,
		"networkId": 250,
		"icon": "fantom",
		"explorers": [
			{
				"name": "ftmscan",
				"url": "https://ftmscan.com",
				"icon": "ftmscan",
				"standard": "EIP3091"
			}
		]
	},
  {
		"name": "Polygon Mainnet",
		"chain": "Polygon",
		"rpc": [
			"https://polygon-rpc.com/",
			"https://rpc-mainnet.matic.network",
			"https://matic-mainnet.chainstacklabs.com",
			"https://rpc-mainnet.maticvigil.com",
			"https://rpc-mainnet.matic.quiknode.pro",
			"https://matic-mainnet-full-rpc.bwarelabs.com"
		],
		"faucets": [],
		"nativeCurrency": {
			"name": "MATIC",
			"symbol": "MATIC",
			"decimals": 18
		},
		"infoURL": "https://polygon.technology/",
		"shortName": "MATIC",
		"chainId": 137,
		"networkId": 137,
		"slip44": 966,
		"explorers": [
			{
				"name": "polygonscan",
				"url": "https://polygonscan.com",
				"standard": "EIP3091"
			}
		]
	},
]