import { List, ListItem, ListIcon, Flex, Box, Text, Stack, Skeleton, Center } from '@chakra-ui/react'
import { MdCheckCircle } from 'react-icons/md'
import { dateDiff } from '../utils/utils'
function EnsList(props) {
  let dataList = props.dataList || []
  let arr = new Array(100).fill(0)
  return (
    <List spacing={3}>
      {
        dataList && dataList.length ? (
          dataList.map((e, index) => (
            <ListItem key={index}>
              <Flex alignItems='center'>
                <Box flex='1'>
                  <Flex alignItems='center'>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    <Text>{e.domain.name}</Text>
                  </Flex>
                </Box>
                <Box fontSize='12px' color='#718096'>{dateDiff(e.registrationDate * 1000)}</Box>
              </Flex>
            </ListItem>
          ))
        ) :
        (
          arr.map((e, index) => (
            <ListItem key={index}>
              <Stack><Skeleton height='20px' /></Stack>
            </ListItem>
          ))
        )
      }
    </List>
  )
}

export default EnsList;