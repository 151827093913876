import React from "react";
import {getLs, setLs} from '../service/service'

const initState = {
  cartList: [],
  contract: null,
  userAddress: ''
}

function reducer (state, action) {
  switch (action.type) {
    case "updateCart":
      return { ...state, cartList: action.data }
    case "updateContract":
      console.log(action)
      return { ...state, contract: action.contractData, userAddress: action.userAddress }
    default:
      return state;
  }
}

const StateContext = React.createContext();
const DispatchContext = React.createContext();

function useStore() {
  return React.useContext(StateContext);
}
function useDispatch() {
  return React.useContext(DispatchContext);
}

function CountStoreProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export { useStore, useDispatch, CountStoreProvider };
