import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Skeleton, Stack, Flex } from '@chakra-ui/react'
import { formatDate } from '../utils/utils'
import {getLs, setLs} from '../service/service'
function EnsTable(props) {
  let dataList = props.dataList
  let arr = new Array(100).fill(0)
  let collect = async (domain) => {
    if (!domain || !domain.id) return
    let collectList = await getLs('collectList') || []
    collectList.push(domain)
    setLs('collectList', collectList).then(res => {
      props.getCollectList()
    })
  }
  let unCollect = async (domain) => {
    let collectList = await getLs('collectList') || []
    collectList = collectList.filter(e => e.id != domain.id)
    setLs('collectList', collectList).then(res => {
      props.getCollectList()
    })
  }
  let addCart = async (domain) => {
    let cartList = await getLs('cartList') || []
    cartList.push(domain)
    setLs('cartList', cartList).then(res => {
      props.getCartList()
    })
  }
  return (
    <TableContainer>
      <Table size='sm'>
        <Thead>
          <Tr height="24px">
            <Th fontSize="12px" color="#718096" textTransform="lowercase" fontWeight="400">name</Th>
            <Th fontSize="12px" color="#718096" textTransform="lowercase" fontWeight="400">expiration</Th>
            <Th fontSize="12px" color="#718096" textTransform="lowercase" fontWeight="400">available</Th>
            <Th fontSize="12px" color="#718096" textTransform="lowercase" fontWeight="400">methods</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            dataList && dataList.length ? (
              dataList.map((e, index) => (
                <Tr height="41px" key={index}>
                  <Td maxWidth="150px" whiteSpace="pre-wrap">{e.domain.name}</Td>
                  <Td>{formatDate('YY-mm-dd', e.expiryDate * 1000)}</Td>
                  <Td>{formatDate('YY-mm-dd', (+e.expiryDate + 86400 * 90) *1000)}</Td>
                  <Td>
                    <Flex alignItems='center'>
                      {
                        props.dataType && props.dataType == 'collected' ? (
                          <svg t="1658855304033" cursor='pointer' onClick={() => unCollect(e)} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3598" width="18" height="18"><path d="M202.666667 256h-42.666667a32 32 0 0 1 0-64h704a32 32 0 0 1 0 64H266.666667v565.333333a53.333333 53.333333 0 0 0 53.333333 53.333334h384a53.333333 53.333333 0 0 0 53.333333-53.333334V352a32 32 0 0 1 64 0v469.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H320c-64.8 0-117.333333-52.533333-117.333333-117.333334V256z m224-106.666667a32 32 0 0 1 0-64h170.666666a32 32 0 0 1 0 64H426.666667z m-32 288a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z m170.666666 0a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z" p-id="3599" fill="#1a202c"></path></svg>
                        ) : 
                        e.collected ? (
                          <svg t="1658854638936" onClick={() => unCollect(e)} cursor='pointer' viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2240" width="18" height="18"><path d="M892.543016 224.150106c-9.284457-11.914354-17.804505-21.814842-26.454512-30.930453C759.437485 80.827887 642.682341 92.003414 536.033369 176.799682c-9.493212 7.547907-18.453281 15.383362-26.88737 23.346731-8.43409-7.963369-17.395182-15.798824-26.888394-23.346731C375.608633 92.003414 258.853489 80.827887 152.202471 193.21863c-8.650007 9.115612-17.170055 19.016099-25.559119 29.714765C-2.680039 410.134984 68.411089 595.897805 259.728416 764.030084c42.320874 37.192064 87.560218 70.64906 132.799562 99.905384 15.841803 10.245342 30.570249 19.244296 43.816948 26.932396 8.024767 4.657067 13.827937 7.872295 17.044188 9.578146 4.869914 2.916423 9.728572 5.142114 14.530948 6.771217 3.470031 1.619894 7.516184 3.091408 12.218276 4.387937 25.377994 6.998391 62.97938 1.908466 85.839017-11.764951 2.14178-1.101077 7.944949-4.315282 15.969717-8.972349 13.246699-7.688099 27.974122-16.687054 43.816948-26.932396 45.239344-29.256324 90.478687-62.71332 132.799562-99.905384C949.879885 595.897805 1020.971014 410.134984 892.543016 224.150106z" p-id="2241" fill="#1a202c"></path></svg>
                        ) : (
                          <svg t="1658854688166" onClick={() => collect(e)} cursor='pointer' viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2587" width="18" height="18"><path d="M908.8 214.4c-9.6-12.8-19.2-22.4-28.8-32-112-115.2-230.4-105.6-342.4-16-9.6 6.4-19.2 16-28.8 25.6-9.6-9.6-19.2-16-28.8-25.6-112-86.4-230.4-99.2-342.4 16-9.6 9.6-19.2 19.2-25.6 32-134.4 195.2-60.8 387.2 137.6 560 44.8 38.4 89.6 73.6 137.6 102.4 16 9.6 32 19.2 44.8 28.8 9.6 6.4 12.8 9.6 19.2 9.6 3.2 3.2 6.4 3.2 12.8 6.4 3.2 3.2 9.6 3.2 16 6.4 25.6 6.4 64 3.2 89.6-12.8 3.2 0 9.6-3.2 16-9.6 12.8-6.4 28.8-16 44.8-28.8 48-28.8 92.8-64 137.6-102.4C969.6 598.4 1043.2 406.4 908.8 214.4zM736 732.8c-41.6 35.2-86.4 70.4-131.2 99.2-16 9.6-28.8 19.2-44.8 25.6-6.4 3.2-12.8 6.4-16 9.6-6.4 3.2-16 6.4-25.6 9.6-3.2 0-6.4 0-9.6 0-6.4 0-12.8 0-16 0-3.2 0-3.2 0-3.2 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0-3.2 0-3.2-3.2-3.2 0-6.4-3.2-9.6-3.2-3.2-3.2-9.6-6.4-16-9.6-12.8-6.4-28.8-16-44.8-25.6-44.8-28.8-89.6-60.8-131.2-99.2-179.2-160-243.2-323.2-131.2-489.6 6.4-9.6 16-16 22.4-25.6 89.6-96 182.4-86.4 275.2-12.8 9.6 6.4 16 12.8 22.4 19.2 0 0 0 0 0 0l28.8 32c3.2 3.2 3.2 3.2 6.4 6.4 0 0 0 0 0 0l0 0c3.2-3.2 9.6-9.6 16-16 12.8-12.8 25.6-25.6 41.6-38.4 92.8-73.6 185.6-83.2 275.2 12.8 6.4 9.6 16 16 22.4 25.6C982.4 406.4 918.4 572.8 736 732.8z" p-id="2588" fill="#1a202c"></path></svg>
                        )
                      }
                      {
                        e.inCart ? '' : (
                          <svg t="1658936176147" onClick={() => addCart(e)} style={{marginLeft: '10px', cursor: 'pointer'}} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2261" width="18" height="18"><path d="M803.2 896m-64 0a2 2 0 1 0 128 0 2 2 0 1 0-128 0Z" p-id="2262" fill="#1a202c"></path><path d="M345.6 896m-64 0a2 2 0 1 0 128 0 2 2 0 1 0-128 0Z" p-id="2263" fill="#1a202c"></path><path d="M774.4 118.4c-108.8 0-198.4 89.6-198.4 198.4 0 108.8 89.6 198.4 198.4 198.4 108.8 0 198.4-89.6 198.4-198.4C976 208 886.4 118.4 774.4 118.4zM774.4 464c-83.2 0-150.4-67.2-150.4-147.2 0-83.2 67.2-147.2 150.4-147.2 83.2 0 150.4 67.2 150.4 147.2C924.8 400 857.6 464 774.4 464z" p-id="2264" fill="#1a202c"></path><path d="M368 649.6c0 12.8 12.8 22.4 25.6 22.4l492.8-41.6c12.8 0 22.4-12.8 22.4-25.6 0-12.8-12.8-22.4-25.6-22.4l-492.8 41.6C377.6 624 364.8 633.6 368 649.6z" p-id="2265" fill="#1a202c"></path><path d="M844.8 291.2l-48 0 0-48c0-12.8-9.6-25.6-25.6-25.6s-25.6 9.6-25.6 25.6l0 48-48 0c-12.8 0-25.6 9.6-25.6 25.6 0 12.8 9.6 25.6 25.6 25.6l48 0 0 48c0 12.8 9.6 25.6 25.6 25.6s25.6-9.6 25.6-25.6l0-48 48 0c12.8 0 25.6-9.6 25.6-25.6C867.2 304 857.6 291.2 844.8 291.2z" p-id="2266" fill="#1a202c"></path><path d="M329.6 192l188.8 0c12.8 0 25.6-9.6 25.6-25.6 0-12.8-9.6-25.6-25.6-25.6l-188.8 0c-12.8 0-25.6 9.6-25.6 25.6C304 182.4 316.8 192 329.6 192z" p-id="2267" fill="#1a202c"></path><path d="M886.4 742.4l-524.8 0c-28.8 0-57.6-25.6-64-51.2l-92.8-547.2c-9.6-51.2-57.6-92.8-112-92.8l-22.4 0c-12.8 0-25.6 9.6-25.6 25.6 0 12.8 9.6 25.6 25.6 25.6l22.4 0c28.8 0 57.6 22.4 64 51.2l92.8 547.2c9.6 51.2 57.6 92.8 112 92.8l524.8 0c12.8 0 25.6-9.6 25.6-25.6C912 755.2 899.2 742.4 886.4 742.4z" p-id="2268" fill="#1a202c"></path></svg>
                        )
                      }
                    </Flex>
                  </Td>
                </Tr>
              ))
            ) : (
              arr.map((e, index) => (
                <Tr height="41px" key={index}>
                  <Td maxWidth="150px" whiteSpace="pre-wrap"><Stack><Skeleton height='20px' /></Stack></Td>
                  <Td><Stack><Skeleton height='20px' /></Stack></Td>
                  <Td><Stack><Skeleton height='20px' /></Stack></Td>
                  <Td><Stack><Skeleton height='20px' /></Stack></Td>
                </Tr>
              ))
            )
          }
        </Tbody>
      </Table>
    </TableContainer>
  )
}

export default EnsTable;
