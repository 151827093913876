import {
  List, ListItem, Flex, Box, Text, Spinner, CircularProgress, CircularProgressLabel, Center, Checkbox, CheckboxGroup, Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton, Button
} from '@chakra-ui/react'
import { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers'
import { useStore, useDispatch } from '../store/Context';
import { getLs, setLs } from '../service/service'
import { useCountDown } from './CountDown'
import { createStandaloneToast } from '@chakra-ui/toast'
const { toast } = createStandaloneToast()
function CartList(props) {
  const isIndeterminate = useRef('')
  const allChecked = useRef(false)
  const dispatch = useDispatch()
  const { contract, userAddress } = useStore()
  const [value, setValue] = useState([])
  const [step, setStep] = useState(1)
  const [cartList, setCartList] = useState([])
  const [totalPrice, setTotalPrice] = useState(0)
  const [seconds, setSeconds] = useCountDown(-1)
  let dataList = props.dataList || []
  dataList.map(e => e.duration = 31536000)
  let getMultPriceList = async () => {
    let domains = dataList.map(e => e.labelName)
    let durations = dataList.map(e => e.duration)
    console.log(contract, domains, durations)
    contract.getMultPriceList(domains, durations).then(res => {
      console.log(res)
      let resData = res.map(e => ethers.utils.formatEther(ethers.BigNumber.from(e).toString()))
      dataList.forEach((e, index) => {
        e.price = resData[index]
      })
      setCartList(dataList)
    }).catch(err => {
      console.log(err)
    })
  }
  let getMultPrice = async () => {
    let durations = value.map(e => 31536000)
    console.log(value)
    contract.getMultPrice(value, durations).then(res => {
      let resData = ethers.utils.formatEther(ethers.BigNumber.from(res).toString())
      console.log(res, resData)
      setTotalPrice(resData)
      // dataList.forEach((e, index) => {
      //   e.price = resData[index]
      // })
    }).catch(err => {
      console.log(err)
    })
  }

  // 从购物车删除域名
  const delItem = async (domain) => {
    let cartList = await getLs('cartList') || []
    cartList = cartList.filter(e => e.id != domain.id)
    setLs('cartList', cartList).then(res => {
      dispatch({ type: "updateCart", data: res });
    })
  }

  // 选择框变化
  const checkboxChange = (e) => {
    console.log(e)
    let list = cartList.filter(e => (+e.price) != 0)
    if (!e.length) {
      allChecked.current = false
      isIndeterminate.current = ''
    } else if (e.length < list.length) {
      isIndeterminate.current = true
      allChecked.current = false
    } else {
      allChecked.current = true
      isIndeterminate.current = ''
    }
    setValue(e)
  }

  // 选择全部
  const checkboxAll = (e) => {
    if (!allChecked.current) {
      allChecked.current = true
      let list = cartList.filter(e => (+e.price) != 0)
      isIndeterminate.current = ''
      setValue(list.map(e => e.labelName))
    } else {
      allChecked.current = false
      setValue([])
    }
  }
  const delAll = () => {
    console.log(2)
  }

  //请求注册
  const multCommit = () => {
    let secret = "0x663a710286e69639796b424841733b32b8899e8f3e23ac08addbabfd23f3b525"
    contract.multCommit(value, secret).then(res => {
      setStep(2)
      setSeconds(-1)
      getTransactionReceipt(res.hash)
    }).catch(err => {
      if (err.error.code == -32000 && err.error.message == 'execution reverted') {
        setStep(2)
        setSeconds(60)
      }
    })
  }

  // 注册
  const multRegist = () => {
    if (seconds > 0) return
    let secret = "0x663a710286e69639796b424841733b32b8899e8f3e23ac08addbabfd23f3b525"
    let durations = value.map(e => 31536000)
    contract.getMultPrice(value, durations).then(res => {
      let resData = ethers.BigNumber.from(res).toString()
      contract.multRegist(value, durations, secret, {
        value: resData
      }).then(async () => {
        let cartList = await getLs('cartList') || []
        cartList = cartList.filter(e => !value.includes(e.labelName))
        setLs('cartList', cartList).then(res => {
          dispatch({ type: "updateCart", data: res });
        })
        setStep(1)
        setValue([])
        setSeconds(-1)
        props.onClose()
        toast({
          title: '成功',
          description: '注册成功！',
          status: 'success',
          duration: 3000,
          position: 'top',
          isClosable: true,
        })
      }).catch(err => {
        console.log(err)
        toast({
          title: '错误',
          description: err,
          status: 'error',
          position: 'top',
          duration: 5000,
          isClosable: true,
        })
      })
    }).catch(err => {
      console.log(err)
    })
  }

  // 是否上链
  const getTransactionReceipt = (hash) => {
    try {
      let provider = ethers.getDefaultProvider('Rinkeby'.toLowerCase())
      if (provider.getTransactionReceipt) provider.getTransactionReceipt(hash).then((transactionReceipt) => {
        if (transactionReceipt) {
          console.log(transactionReceipt)
          setSeconds(60)
        } else {
          setTimeout(() => getTransactionReceipt(hash), 1000) 
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const cancelRegist = () => {
    setSeconds(-1)
    setStep(1)
  }

  useEffect(() => {
    if (contract && dataList.length) {
      getMultPriceList()
    }
  }, [contract, dataList])
  useEffect(() => {
    if (contract && dataList.length) {
      getMultPrice()
    }
  }, [value])
  // setCartState({cartList: dataList})
  // let duration = 31536000
  // let secret = "0x663a710286e69639796b424841733b32b8899e8f3e23ac08addbabfd23f3b525" //第一步commit时随机数,这里直接使用固定字符串
  // let domain = dataList[0].labelName
  // let resolver = "0xf6305c19e814d2a75429Fd637d01F7ee0E77d615"
  // contract.makeCommitmentWithConfig(domain, userAddress, secret, resolver, userAddress).then(async res => {
  //   console.log(res)
  //   let commit = await contract.commit(res)
  //   console.log(commit)
  // })
  // contract.rentPrice(domain, duration).then(res => {
  //   let value = ethers.BigNumber.from(res).toString()
  //   console.log(value)
  //   contract.registerWithConfig(domain, userAddress, duration, secret, resolver, userAddress, {
  //     gasPrice: ethers.utils.parseUnits('20', 'gwei'),
  //     value: value
  //   }).then(e => {
  //     console.log(e)
  //   }, err => { console.log("err", err) })
  // })
  return (
    <Drawer
      isOpen={props.isOpen}
      placement={props.placement}
      onClose={props.onClose}
      finalFocusRef={props.btnRef}
      size='sm'
    >
      <DrawerOverlay />
      {
        step == 1 ? (
          <DrawerContent>
            <DrawerCloseButton zIndex='999' />
            <DrawerHeader position='absolute' top='0' left='0' right='0' zIndex='99' background='#ffffff' boxShadow='0 4px 6px -1px rgba(0, 0, 0, 0.1)'>
              <Box>购物车</Box>
              <Flex alignItems='center' mt='18px'>
                <Checkbox outline='none' isChecked={allChecked.current} isIndeterminate={isIndeterminate.current} defaultChecked onChange={() => checkboxAll()} ><Text fontSize='12px' color='#718096'>全选</Text></Checkbox>
                <Flex alignItems='center' cursor='pointer' marginLeft='12px' onClick={() => delAll()}>
                  <svg t="1658855304033" style={{ marginLeft: '10px', cursor: 'pointer', marginLeft: 'auto' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3598" width="18" height="18"><path d="M202.666667 256h-42.666667a32 32 0 0 1 0-64h704a32 32 0 0 1 0 64H266.666667v565.333333a53.333333 53.333333 0 0 0 53.333333 53.333334h384a53.333333 53.333333 0 0 0 53.333333-53.333334V352a32 32 0 0 1 64 0v469.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H320c-64.8 0-117.333333-52.533333-117.333333-117.333334V256z m224-106.666667a32 32 0 0 1 0-64h170.666666a32 32 0 0 1 0 64H426.666667z m-32 288a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z m170.666666 0a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z" p-id="3599" fill="#718096"></path></svg>
                  <Text fontSize='12px' color='#718096' marginLeft='4px'>删除</Text>
                </Flex>
              </Flex>
            </DrawerHeader>
            <DrawerBody paddingTop='100px'>
              <List spacing={3}>
                {
                  cartList && cartList.length ? (
                    <CheckboxGroup onChange={checkboxChange} value={value}>
                      {
                        cartList.map((e, index) => (
                          <ListItem key={index} marginTop='10px'>
                            <Flex alignItems='center'>
                              <Box flex='1'>
                                <Flex alignItems='center'>
                                  <Checkbox outline='none' defaultChecked isDisabled={(+e.price) == 0} value={e.labelName}>{e.domain.name}</Checkbox>
                                </Flex>
                              </Box>
                              <Box fontSize='12px' color='#718096' textAlign='right'>
                                {
                                  (+e.price) == 0 ? (
                                    <Text color='red' fontSize='14px'>已被注册</Text>
                                  ) : (
                                    <Text>{(+e.price).toFixed(4)}ETH</Text>
                                  )
                                }
                                <svg t="1658855304033" onClick={() => delItem(e)} style={{ marginLeft: '10px', cursor: 'pointer', marginLeft: 'auto', marginTop: '2px' }} viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3598" width="18" height="18"><path d="M202.666667 256h-42.666667a32 32 0 0 1 0-64h704a32 32 0 0 1 0 64H266.666667v565.333333a53.333333 53.333333 0 0 0 53.333333 53.333334h384a53.333333 53.333333 0 0 0 53.333333-53.333334V352a32 32 0 0 1 64 0v469.333333c0 64.8-52.533333 117.333333-117.333333 117.333334H320c-64.8 0-117.333333-52.533333-117.333333-117.333334V256z m224-106.666667a32 32 0 0 1 0-64h170.666666a32 32 0 0 1 0 64H426.666667z m-32 288a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z m170.666666 0a32 32 0 0 1 64 0v256a32 32 0 0 1-64 0V437.333333z" p-id="3599" fill="#718096"></path></svg>
                              </Box>
                            </Flex>
                          </ListItem>
                        ))
                      }
                    </CheckboxGroup>
                  ) :
                    (
                      <Center>暂无内容</Center>
                    )
                }
              </List>
            </DrawerBody>
            <DrawerFooter boxShadow='0 -4px 10px 0px rgba(0, 0, 0, 0.05)'>
              <Flex alignItems='center' width='100%' justifyContent='space-between'>
                <Box>
                  <Text fontSize='12px' color='#718096'>总金额（不含gas）</Text>
                  <Text fontSize='14px' fontWeight='600'>{totalPrice}ETH</Text>
                </Box>
                <Button onClick={() => multCommit()}>
                  请求注册
                </Button>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        ) : (
          <DrawerContent>
            <DrawerHeader position='absolute' top='0' left='0' right='0' zIndex='99' background='#ffffff' boxShadow='0 4px 6px -1px rgba(0, 0, 0, 0.1)'>
              <Box>{seconds == 0 ? '完成注册' : '等一分钟'}</Box>
              <Text fontSize='12px' color='#718096' marginTop='12px'>{seconds == 0 ? '点击“注册”按钮，并在钱包中再次确认一笔交易，只有在这次交易确认后，才能确定是不是成功注册了这个域名.' : '需要等待一段时间，以确保其他人没有尝试注册相同的名字，同时也是在保护你的注册请求。'}</Text>
            </DrawerHeader>
            <DrawerBody paddingTop='100px'>
              {
                seconds < 0 ? (
                  <Center height='100%'>
                    <Spinner
                      thickness='4px'
                      speed='0.65s'
                      emptyColor='gray.200'
                      color='blue.500'
                      size='xl'
                      margin='50px auto'
                    />
                  </Center>
                ) : seconds > 0 ? (
                  <Center height='100%'>
                    <CircularProgress value={100 - ((seconds / 60) * 100)} size='100px' margin='50px auto'>
                      <CircularProgressLabel>{seconds}</CircularProgressLabel>
                    </CircularProgress>
                  </Center>
                  
                ) : ''
              }
            </DrawerBody>
            <DrawerFooter boxShadow='0 -4px 10px 0px rgba(0, 0, 0, 0.05)'>
              <Flex alignItems='center' width='100%' justifyContent='space-between'>
                <Box>
                  <Text fontSize='12px' color='#718096'>总金额（不含gas）</Text>
                  <Text fontSize='14px' fontWeight='600'>{totalPrice}ETH</Text>
                </Box>
                <Box>
                  <Button onClick={() => cancelRegist()}>
                    取消
                  </Button>
                  <Button isDisabled={seconds != 0} onClick={() => multRegist()} marginLeft='12px'>
                    注册
                  </Button>
                </Box>
              </Flex>
            </DrawerFooter>
          </DrawerContent>
        )
      }
    </Drawer>
  )
}

export default CartList;