import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react'
import Index from "../Index/index";
import Header from "../components/Header"
import Bar from "../Bar";
import './App.css';
import { CountStoreProvider } from '../store/Context';

function App() {
  return (
    <ChakraProvider>
      <CountStoreProvider>
        <Header />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/bar" element={<Bar />} />
          </Routes>
        </BrowserRouter>
      </CountStoreProvider>
    </ChakraProvider>
  );
}

export default App;
