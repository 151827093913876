export const resultId = () => {
  return {
    query: `query GetResult($query_id: Int!, $parameters: [Parameter!]) {
      get_result_v2(query_id: $query_id, parameters: $parameters) {
        job_id
        result_id
        error_id
        __typename
      }
    }`
  }
}

export const resultDataByResult = () => {
  return {
    query: `query FindResultDataByResult($result_id: uuid!, $error_id: uuid!) {
      query_results(where: {id: {_eq: $result_id}}) {
        id
        job_id
        runtime
        generated_at
        columns
        __typename
      }
      query_errors(where: {id: {_eq: $error_id}}) {
        id
        job_id
        runtime
        message
        metadata
        type
        generated_at
        __typename
      }
      get_result_by_result_id(args: {want_result_id: $result_id}) {
        data
        __typename
      }
    }`
  }
}

export const registrations = () => {
  return {
    query: `query {
      registrations(first: 100, orderBy: registrationDate, orderDirection: desc) {
        registrationDate
        expiryDate
        domain {
          name
          owner {
            id
          }
        }
      }
    }`
  }
}

export const registrationsById = () => {
  return {
    query: `query getRegistrationsById($ids: [ID]) {
      registrations(first: 1000, where: {domain_in: $ids}) {
        id
        domain {
          id
          name
          __typename
          isMigrated
          owner
          resolver {
            id
            address
            addr
            contentHash
            texts
            coinTypes
          }
        }
        registrationDate
        expiryDate
        cost
        labelName
      }
    }`
  }
}

export const expirationRegistrations = () => {
  return {
    query: `query(
      $expiryDate_gte: BigInt
      $expiryDate_lte: BigInt
    ) {
      registrations(
        orderBy: expiryDate,
        first: 1000
        orderDirection: desc, 
        where: {
          expiryDate_gte: $expiryDate_gte,
          expiryDate_lte: $expiryDate_lte
        }
      ) {
        id
        domain {
          id
          name
          __typename
        }
        registrationDate
        expiryDate
        cost
        labelName
      }
    }`
  }
}
