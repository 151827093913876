import EnsTable from '../components/Table'
import EnsList from '../components/List'
import { Text, Grid, GridItem, Box } from '@chakra-ui/react'
import { getResultId, getResultDataByResult, getRegistrationsById, getRegistrations, getExpirationRegistrations } from '../http/api'
import { useState, useEffect, useRef } from 'react';
import {getLs, setLs} from '../service/service'
import './index.scss';
import { createStandaloneToast } from '@chakra-ui/toast'
import { useDispatch } from "../store/Context"
const { toast } = createStandaloneToast()
let interval
let interval1
let interval2
function App() {
  const releaseListState = useRef([])
  const dispatch = useDispatch()
  const [registrationsState, setregistrationsState] = useState({
    registrations: []
  })
  // const [releaseListState, setReleaseListState] = useState({
  //   releaseList: []
  // })
  const [collectListState, setCollectListState] = useState({
    collectList: []
  })
  let getCollectListFun = () => {
    getLs('collectList').then(res => {
      let collectList = res || []
      let releaseList = releaseListState.current
      releaseList.forEach(e => {
        if(collectList.some(el => el.id == e.id)) {
          e.collected = true
        } else {
          e.collected = false
        }
      })
      releaseListState.current = releaseList
      // setReleaseListState({releaseList})
      // setCollectListState({collectList})
      getCartListFun(collectList)
    })
  }
  let getRegistrationsFun = () => {
    getRegistrations().then(res => {
      let registrations = res.data.registrations
      setregistrationsState({registrations})
    })
  }
  let getExpirationRegistrationsFun = () => {
    let expiryDate_lte = Math.floor(new Date().getTime() / 1000) - (86400 * 90)
    let expiryDate_gte = expiryDate_lte - (86400 * 90)
    getExpirationRegistrations({variables: {expiryDate_gte, expiryDate_lte}}).then(res => {
      let releaseList = res.data.registrations
      releaseListState.current = releaseList
      getCartListFun()
      // setReleaseListState({releaseList})
    })
  }
  let getRegistrationsByIdFun = async () => {
    let collectList = await getLs('collectList')
    let ids = collectList.map(e => e.domain.id)
    getRegistrationsById({variables: {ids}}).then(res => {
      setLs('collectList', res.data.registrations).then(res => {
        getCollectListFun()
      })
    })
  }

  let getCartListFun = async (collect) => {
    let cartList = await getLs('cartList')
    dispatch({ type: "updateCart", data: cartList});
    let collectList = collect || await getLs('collectList')
    let releaseList = releaseListState.current
    releaseList.forEach(e => {
      if(cartList.some(el => el.id == e.id)) {
        e.inCart = true
      } else {
        e.inCart = false
      }
    })
    collectList.forEach(e => {
      if(cartList.some(el => el.id == e.id)) {
        e.inCart = true
      } else {
        e.inCart = false
      }
    })
    releaseListState.current = releaseList
    // setReleaseListState({releaseList})
    setCollectListState({collectList})
  }
  useEffect(() => {
    getExpirationRegistrationsFun()
    getRegistrationsFun()
    getRegistrationsByIdFun()
  }, [])
  useEffect(() => {
    interval = setInterval(() => {
      getRegistrationsFun()
    }, 20000)
    interval1 = setInterval(() => {
      getExpirationRegistrationsFun()
    }, 600000)
    interval2 = setInterval(() => {
      getRegistrationsByIdFun()
    }, 600000)
    const listener = ev => {
      ev.preventDefault()
      clearInterval(interval)
      clearInterval(interval1)
      clearInterval(interval2)
    }
    window.addEventListener('beforeunload', listener);
    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [])
  return (
    <div>
      <Grid templateColumns='repeat(25, 1fr)' gap={4} className='App'>
        <GridItem colSpan={(collectListState.collectList && collectListState.collectList.length) ? 9 : 13} borderWidth='1px' borderRadius='10px' padding='16px'>
          <Text fontSize='20px' fontWeight='550' lineHeight='2' marginBottom='.5em'>赎回期</Text>
          <Box h='600px' overflow='auto'>
            <EnsTable dataList={releaseListState.current || []} getCollectList={() => getCollectListFun()} getCartList={() => getCartListFun()} />
          </Box>
        </GridItem>
        {
          collectListState.collectList && collectListState.collectList.length ? (
            <GridItem colSpan={9} borderWidth='1px' borderRadius='10px' padding='16px'>
              <Text fontSize='20px' fontWeight='550' lineHeight='2' marginBottom='.5em'>关注列表</Text>
              <Box h='600px' overflow='auto'>
                <EnsTable dataList={collectListState.collectList || []} dataType="collected" getCollectList={() => getCollectListFun()} getCartList={() => getCartListFun()} />        
              </Box>
            </GridItem>
          ) : ''
        }
        
        <GridItem colSpan={(collectListState.collectList && collectListState.collectList.length) ? 7 : 12} borderWidth='1px' borderRadius='10px' padding='16px'>
          <Text fontSize='20px' fontWeight='550' lineHeight='2' marginBottom='.5em'>新注册</Text>
          <Box h='600px' overflow='auto'>
            <EnsList dataList={registrationsState.registrations}  />
          </Box>
        </GridItem>
      </Grid>
    </div>
    
  );
}

export default App;
